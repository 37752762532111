import { FeedItem, FeedItemType, FeedId } from '@dashboard/core/models';

export class SetFeed {
  static readonly type = '[Feed] Set feed';

  constructor(public readonly payload: FeedItem[], public readonly hasUnread: boolean) {}
}

export class AppendToFeed {
  static readonly type = '[Feed] Append to feed';

  constructor(
    public readonly payload: FeedItem[],
    public readonly hasUnread: boolean,
    public readonly canLoadMore: boolean
  ) {}
}

export class UpdateFeedItems {
  static readonly type = '[Feed] Update feed items';

  constructor(public readonly payload: FeedItem[], public readonly hasUnread: boolean) {}
}

export class SetFeedHasUnread {
  static readonly type = '[Feed] Set has unread';

  constructor(public readonly payload: boolean) {}
}

export class SetFeedError {
  static readonly type = '[Feed] Set error';

  constructor(public readonly payload: string) {}
}

export class UpdateFeedItemReadStatus {
  static readonly type = '[Feed] Update feed item read status';

  constructor(
    public readonly type: FeedItemType,
    public readonly id: FeedId,
    public readonly isUnread: boolean
  ) {}
}

export class UpdateFeedItemWorkflowStatus {
  static readonly type = '[Feed] Update feed item workflow status';

  constructor(
    public readonly type: FeedItemType,
    public readonly id: FeedId,
    public readonly isClosed: boolean
  ) {}
}
