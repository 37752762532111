export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc'
}

export class DashSort {
  constructor(public field: string, public order: SortOrder) {}

  toString(): string {
    return `${this.field}:${this.order}`;
  }
}
