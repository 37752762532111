import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Subdomain } from '@dashboard/core/models';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  get nativeWindow(): Window {
    return window;
  }

  get subdomain(): Subdomain {
    const subdomain = this.nativeWindow.location.host
      .replace('www.', '')
      .replace(environment.DASHBOARD_HOST, '')
      .split('.')[0];

    return subdomain as Subdomain;
  }

  navigateToRootDomain(): void {
    this.nativeWindow.location.href = `https://${environment.DASHBOARD_HOST}`;
  }

  navigateToSubdomain(subdomain: Subdomain, path?: string): void {
    this.nativeWindow.location.href = `https://${subdomain}.${environment.DASHBOARD_HOST}${
      path ? '/' + path : ''
    }`;
  }
}
