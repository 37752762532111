import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NoSoundDialogComponent } from '../../shared/components/no-sound-dialog/no-sound-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  private sound: HTMLAudioElement;
  private soundTimer: NodeJS.Timer;
  private interval: number | undefined;

  constructor(private $dialog: MatDialog) { }

  play(source: string, interval?: number): void {
    this.sound = new Audio(source);
    this.interval = interval;

    if (interval) {
      this.soundTimer = setInterval(() => {
        this.loadSound();
      }, interval);
    } else {
      this.loadSound();
    }
  }

  stop(): void {
    clearInterval(this.soundTimer);
  }

  private loadSound(): void {
    this.sound.play().catch((e: DOMException) => {
      this.stop();
      if (e.name === 'NotAllowedError') {
        this.requestUserInteraction();
      }
    });
  }

  /**
   * Sound was blocked by browser due to anti-autoplay policies
   * which require user interaction; request user interaction.
   */
  private requestUserInteraction(): void {
    const dialog: MatDialogRef<NoSoundDialogComponent> = this.$dialog.open(
      NoSoundDialogComponent
    );

    dialog.beforeClosed().subscribe(() => {
      this.play(this.sound.src, this.interval);
    });
  }
}
