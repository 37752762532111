import { patch } from '../util/patch';
import { Location } from './location.model';
import { OrganizationId } from './type-tokens';

export class Organization {
  id: OrganizationId;
  name: string;
  location?: Location;
  description?: string;
  parentId?: OrganizationId;

  constructor(seed: Partial<Organization> = {}) {
    patch(this, seed);
  }
}

export class OrganizationTree extends Organization {
  children: OrganizationTree[];

  constructor(seed: Partial<OrganizationTree> = {}) {
    super();
    patch(this, seed);
  }

  hasChildren(): boolean {
    return this.children && this.children.length ? true : false;
  }
}
