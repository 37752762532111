import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChatMessage,
  Message,
  MessageId,
  OrganizationId,
  Tip,
  TipId
} from '@dashboard/core/models';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


export interface TipResponse {
  tip: Tip;
}

export interface TipMessagesResponse {
  messages: ChatMessage[];
}

export interface SendChatMessageResponse {
  id: MessageId;
}

export interface SendChatMessageRequest {
  organizationId?: OrganizationId;
  bodyText: string;
  // attachments: string[];
}

@Injectable({
  providedIn: 'root'
})
export class TipIoService {
  constructor(private http: HttpClient) { }

  fetchTip(tipId: TipId): Observable<TipResponse> {
    const url = `${environment.DASHBOARD_APP_API}/tips/${tipId}`;

    return this.http.get<TipResponse>(url);
  }

  fetchTipMessages(tipId: TipId, sentAfter?: number): Observable<TipMessagesResponse> {
    const url = `${environment.DASHBOARD_APP_API}/tips/${tipId}/messages`;

    let params;
    if (sentAfter) {
      params = { sentAfter };
    }

    return this.http.get<TipMessagesResponse>(url, { params: <HttpParams>params });
  }

  putMarkTipAsOpen(tipId: TipId): Observable<void> {
    const url = `${environment.DASHBOARD_APP_API}/tips/${tipId}/open`;

    return this.http.put<void>(url, {});
  }

  putMarkTipAsClosed(tipId: TipId): Observable<void> {
    const url = `${environment.DASHBOARD_APP_API}/tips/${tipId}/close`;

    return this.http.put<void>(url, {});
  }

  putMarkTipAsRead(tipId: TipId): Observable<void> {
    const url = `${environment.DASHBOARD_APP_API}/tips/${tipId}/read`;

    return this.http.put<void>(url, {});
  }

  postTipMessage(tipId: TipId, message: Message): Observable<SendChatMessageResponse> {
    const request: SendChatMessageRequest = {
      bodyText: message
    };

    const url = `${environment.DASHBOARD_APP_API}/tips/${tipId}/messages`;

    return this.http.post<SendChatMessageResponse>(url, request);
  }
}
