import { style, transition, trigger, animate } from '@angular/animations';

export const slideDown = trigger('slideDown', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)' }),
    animate('200ms', style({ transform: 'translateY(0)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0)', opacity: 1 }),
    animate('200ms', style({ transform: 'translateY(-100%)', opacity: 0 }))
  ])
]);
