import { Tip } from './tip.model';
import { TipId } from './type-tokens';

export enum FeedItemType {
  Tip = 'tip'
}

export interface FeedItemDef<T extends FeedItemType, I> {
  type: T;
  item: I;
}

export type TipFeedItem = FeedItemDef<FeedItemType.Tip, Tip>;
export type FeedItem = TipFeedItem;
export type FeedId = TipId;
