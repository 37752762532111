import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailAddress } from '@dashboard/core';
import { AccountId, EmailId, EnterpriseId, Subdomain } from '@dashboard/core/models';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
export interface ClientOnboardingRequest {
  firstName: string;
  lastName: string;
  email: EmailAddress;
  password: string;
  company: string;
  subdomain: string;
}

export interface ClientOnboardingResponse {
  enterpriseId: EnterpriseId;
  enterpriseDomain: Subdomain;
  accountId: AccountId;
  emailId: EmailId;
  clientOnboardingToken: string;
}


@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private url = `${environment.DASHBOARD_APP_API}/clients`
  constructor(private http: HttpClient) {}

  getSubdomainAvailability(subdomain: Subdomain): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/subdomains/${subdomain}`);
  }

  onboardClient(
    firstName: string,
    lastName: string,
    email: EmailAddress,
    password: string,
    company: string,
    subdomain: Subdomain
  ): Observable<ClientOnboardingResponse> {
    const request = {
      firstName,
      lastName,
      email,
      password,
      company,
      subdomain
    } as ClientOnboardingRequest;

    return this.http.post<ClientOnboardingResponse>(this.url, request);
  }
}
