import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'src/environments/environment';
import { init } from '@sentry/angular';
import { AppModule } from './app/app.module';

if (environment.PROD_MODE) {
  enableProdMode();
}

init({
  dsn: environment.SENTRY_DSN,
  environment: environment.RUNTIME_ENV,
  ignoreErrors: [
    'Non-Error exception captured'
  ]
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
