import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  readonly show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  show(): void {
    this.show$.next(true);
  }

  hide(): void {
    this.show$.next(false);
  }
}
