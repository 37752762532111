import { patch } from '@dashboard/core/util';

export class MarkerOptions implements google.maps.MarkerOptions {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  icon?: string | google.maps.Icon | google.maps.Symbol;

  constructor(seed: Partial<MarkerOptions> = {}) {
    this.icon =
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjhweCIgaGVpZ2h0PSIzNHB4IiB2aWV3Qm94PSIwIDAgMjggMzQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUzICg3MjUyMCkgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+bWFwLWljb248L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz4KICAgICAgICA8bGluZWFyR3JhZGllbnQgeDE9IjE2LjA4OTk2NTQlIiB5MT0iMCUiIHgyPSI4My45MTAwMzQ2JSIgeTI9IjEwMCUiIGlkPSJsaW5lYXJHcmFkaWVudC0xIj4KICAgICAgICAgICAgPHN0b3Agc3RvcC1jb2xvcj0iIzZERTFGNyIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjMzBCOURBIiBvZmZzZXQ9IjEwMCUiPjwvc3RvcD4KICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgPC9kZWZzPgogICAgPGcgaWQ9ImFjdGl2aXR5IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iYWN0aXZpdHktdGlwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODE4LjAwMDAwMCwgLTI5NC4wMDAwMDApIiBmaWxsPSJ1cmwoI2xpbmVhckdyYWRpZW50LTEpIj4KICAgICAgICAgICAgPHBhdGggZD0iTTgzMiwzMjggQzgyMi42NjY2NjcsMzE5LjM4NjU4MyA4MTgsMzEyLjU3OTg4MyA4MTgsMzA3LjU3OTkwMSBDODE4LDMwMC4wNzk5MjkgODI0LjI2ODAxNCwyOTQgODMyLDI5NCBDODM5LjczMTk4NiwyOTQgODQ2LDMwMC4wNzk5MjkgODQ2LDMwNy41Nzk5MDEgQzg0NiwzMTIuNTc5ODgzIDg0MS4zMzMzMzMsMzE5LjM4NjU4MyA4MzIsMzI4IFogTTgzMiwzMTQuMDEyNDg2IEM4MzUuNjYyNTIsMzE0LjAxMjQ4NiA4MzguNjMxNTc5LDMxMS4xMzI1MiA4MzguNjMxNTc5LDMwNy41Nzk5MDEgQzgzOC42MzE1NzksMzA0LjAyNzI4MyA4MzUuNjYyNTIsMzAxLjE0NzMxNyA4MzIsMzAxLjE0NzMxNyBDODI4LjMzNzQ4LDMwMS4xNDczMTcgODI1LjM2ODQyMSwzMDQuMDI3MjgzIDgyNS4zNjg0MjEsMzA3LjU3OTkwMSBDODI1LjM2ODQyMSwzMTEuMTMyNTIgODI4LjMzNzQ4LDMxNC4wMTI0ODYgODMyLDMxNC4wMTI0ODYgWiIgaWQ9Im1hcC1pY29uIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=';
    patch(this, seed);
  }
}

export const silverGoogleMapsStyle: google.maps.MapTypeStyle[] = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  }
];
