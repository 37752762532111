import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[dashPasswordToggle]',
  exportAs: 'dashPasswordToggle'
})
export class PasswordToggleDirective {
  @Input() input: HTMLInputElement;

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  togglePassword($event: MouseEvent | KeyboardEvent): void {
    $event.preventDefault();

    if (this.isHidden()) {
      this.input.type = 'text';
    } else {
      this.input.type = 'password';
    }
  }

  isHidden(): boolean {
    return this.input.type === 'password';
  }
}
