import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeedItem } from '@dashboard/core/models';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface FeedResponse {
  items: FeedItem[];
  hasUnread: boolean;
}

export interface FeedRequest {
  skip?: number;
  limit?: number;
  search?: string;
  minimumUpdatedAt?: number;
}

@Injectable({
  providedIn: 'root'
})
export class FeedIoService {
  constructor(private http: HttpClient) { }

  fetchFeed(params?: FeedRequest): Observable<FeedResponse> {
    const url = `${environment.DASHBOARD_APP_API}/commsFeed`;

    return this.http.get<FeedResponse>(url, { params: <HttpParams>params });
  }

  fetchFeedSearchResults(params: FeedRequest): Observable<FeedResponse> {
    const url = `${environment.DASHBOARD_APP_API}/commsFeed/search`;

    return this.http.get<FeedResponse>(url, { params: <HttpParams>params });
  }

  fetchHasUnread(): Observable<boolean> {
    const url = `${environment.DASHBOARD_APP_API}/commsFeed/hasUnread`;

    return this.http.get<boolean>(url);
  }
}
