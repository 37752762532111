import { Component } from '@angular/core';

@Component({
  selector: 'dash-loader',
  template: `
    <div class="loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  `,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {}
