import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MediaId, ThumbnailSize } from '@dashboard/core/models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { GET_MEDIA_API, MediaIoService } from './media-io.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private thumbnails: Map<MediaId, SafeUrl> = new Map<MediaId, SafeUrl>();
  private media: Map<MediaId, SafeUrl> = new Map<MediaId, SafeUrl>();

  constructor(private $io: MediaIoService, private $sanitizer: DomSanitizer) { }

  getMedia(mediaId: MediaId): Observable<SafeUrl> {
    const existing = this.media.get(mediaId);
    if (existing) {
      return of(existing);
    }

    return this.$io.fetchMedia(mediaId).pipe(
      map((blob: Blob) => this.$sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))),
      tap((mediaUrl: SafeUrl) => this.media.set(mediaId, mediaUrl))
    );
  }

  getThumbnailImage(
    mediaId: MediaId,
    thumbnailSize: ThumbnailSize = ThumbnailSize.XSMALL
  ): Observable<SafeUrl> {
    const existing = this.thumbnails.get(mediaId);
    if (existing) {
      return of(existing);
    }

    return this.$io.fetchThumbnail(mediaId, thumbnailSize).pipe(
      map((blob: Blob) => this.$sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))),
      tap((mediaUrl: SafeUrl) => this.thumbnails.set(mediaId, mediaUrl))
    );
  }

  getVideoUrl(mediaId: MediaId): SafeUrl {
    return this.$sanitizer.bypassSecurityTrustUrl(`${GET_MEDIA_API}/${mediaId}`);
  }
}
