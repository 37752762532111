import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  isSmallScreen$: Observable<boolean> = this.$breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(
      map((res: BreakpointState) => res.matches),
      distinctUntilChanged(),
      shareReplay(1)
    );

  constructor(private $breakpointObserver: BreakpointObserver) { }
}
