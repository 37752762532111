import { MediaId } from './type-tokens';

export interface Attachment {
  mediaId: MediaId;
  contentType: ContentType;
}

/**
 * Supported content types
 */
export enum ContentType {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_GIF = 'image/gif',
  IMAGE_PNG = 'image/png',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_MPEG = 'video/mpeg',
  VIDEO_QUICKTIME = 'video/quicktime',
  PDF = 'application/pdf'
}

export class ContentTypeUtils {
  images: string[] = [ContentType.IMAGE_JPEG, ContentType.IMAGE_GIF, ContentType.IMAGE_PNG];
  videos: string[] = [ContentType.VIDEO_MP4, ContentType.VIDEO_MPEG, ContentType.VIDEO_QUICKTIME];
  other: string[] = [ContentType.PDF];

  constructor(private contentType: ContentType) {}

  isImage(): boolean {
    return this.images.indexOf(this.contentType) >= 0;
  }

  isVideo(): boolean {
    return this.videos.indexOf(this.contentType) >= 0;
  }

  isSupported(): boolean {
    return this.isImage() || this.isVideo() || this.other.indexOf(this.contentType) >= 0;
  }
}

export enum ThumbnailSize {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  GALLERY = 'gallery'
}
