import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatMessage, Message, MessageId, Tip, TipId } from '@dashboard/core/models';

import {
  SendChatMessageResponse,
  TipIoService,
  TipMessagesResponse,
  TipResponse
} from './tip-io.service';

@Injectable({
  providedIn: 'root'
})
export class TipService {
  constructor(private $io: TipIoService) { }

  getTip(tipId: TipId): Observable<Tip> {
    return this.$io.fetchTip(tipId).pipe(map((res: TipResponse) => res.tip));
  }

  getTipMessages(tipId: TipId, sentAfter?: number): Observable<ChatMessage[]> {
    return this.$io
      .fetchTipMessages(tipId, sentAfter)
      .pipe(map((res: TipMessagesResponse) => res.messages));
  }

  markTipAsOpen(tipId: TipId): Observable<void> {
    return this.$io.putMarkTipAsOpen(tipId);
  }

  markTipAsClosed(tipId: TipId): Observable<void> {
    return this.$io.putMarkTipAsClosed(tipId);
  }

  markTipAsRead(tipId: TipId): Observable<void> {
    return this.$io.putMarkTipAsRead(tipId);
  }

  sendTipMessage(tipId: TipId, message: Message): Observable<MessageId> {
    return this.$io
      .postTipMessage(tipId, message)
      .pipe(map((res: SendChatMessageResponse) => res.id));
  }
}
